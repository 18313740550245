import { AppMessageKeys } from '../translations/messages.ts'

export const ADVANCED_LESSON_TAG = 'Vocab practice ML'
export const VOCAB_LESSON_TAGS = [
  'Vocab practice MW',
  'Vocab practice MA',
  'Vocab practice MC',
  'Vocab practice CL',
  'Vocab practice ML',
]

export interface MockVocabLesson {
  img: string
  lessonText: string
  lessonType: AppMessageKeys
  lessonTag: string
  tag: string
}

export const MOCK_VOCAB_LESSONS: MockVocabLesson[] = [
  {
    img: '/images/program/lessons/kimono.png',
    lessonText: 'Match Game',
    lessonType: 'Word-Translation Matching',
    lessonTag: 'Vocab practice MW',
    tag: 'Match',
  },
  {
    img: '/images/program/lessons/speaker.png',
    lessonType: 'Word-Pronunciation Matching',
    lessonText: 'Sound Game',
    lessonTag: 'Vocab practice MA',
    tag: 'Sound',
  },
  {
    img: '/images/program/lessons/basketball.png',
    lessonType: 'Multiple choice',
    lessonText: 'Choice Game',
    lessonTag: 'Vocab practice MC',
    tag: 'Choice',
  },
  {
    img: '/images/program/lessons/teacher.png',
    lessonType: 'Complex Lesson',
    lessonText: 'Big Word Game',
    lessonTag: 'Vocab practice CL',
    tag: 'Big word',
  },
]

export const MOCK_ADVANCED_LESSON: MockVocabLesson[] = [
  {
    img: '/images/program/lessons/tennis.png',
    lessonType: 'Pick Your Own Words',
    lessonText: 'Play Your Game',
    lessonTag: ADVANCED_LESSON_TAG,
    tag: 'Play your game',
  },
]
