import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
} from 'react-router-dom'
import { ErrorPage } from '../pages/errorPage.tsx'
import { Login } from '../features/auth/login.tsx'
import { Layout } from './layout.tsx'
import { isWebDomain, urls } from '../shared/urls.ts'
import { ChatPage } from '../pages/chatPage.tsx'
import { ProfilePage } from '../pages/profilePage.tsx'
import { ConfirmRegistration } from '../features/auth/confirmRegistration.tsx'
import { OnboardingPage, OnboardingProvider } from '../pages/onboardingPage.tsx'
import { ProgramPage } from '../pages/programPage.tsx'
import { NotFoundPage } from '../pages/notFoundPage.tsx'
import { PaymentStatusPage } from '../pages/paymentStatusPage.tsx'
import { SubscriptionsPage } from '../pages/subscriptionsPage.tsx'
import { DemoPage } from '../pages/demoPage.tsx'
import { ContactsPage } from '../pages/contactsPage.tsx'
import { appVersion } from '../shared/lib/utils.ts'
import {
  AuthAccess,
  ConfirmAccess,
  DemoAccess,
  LoginAccess,
  SubscriptionsAccess,
} from './guards.tsx'
import { ProgramPage2 } from '../pages/programPage2.tsx'
import { ProgressPage } from '../pages/progressPage.tsx'
import { PracticePage } from '../pages/practicePage.tsx'
import { VocabPage } from '../pages/vocabularyPage.tsx'
import { WordsPage } from '../pages/wordsPage.tsx'
import { PasswordRecovery } from '../pages/passwordRecovery.tsx'
import { TrialInfoPage } from '../pages/trialInfoPage.tsx'

const chooseYourLevel = isWebDomain
  ? {
      path: urls.chooseyourlevel,
      element: <ProgramPage registration={true} />,
    }
  : {}

export const routeObjects: RouteObject[] = [
  {
    path: '/',
    element: (
      <OnboardingProvider>
        <AuthAccess>
          <Layout
            withMenu={false}
            withNavbar={true}
            withSubscribeBtn={true}
            scroll={true}
          />
        </AuthAccess>
      </OnboardingProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={urls.learningProfile()} />,
      },
      {
        path: urls.learningProfile('*'),
        element: <ProgramPage2 registration={false} />,
      },
    ],
  },

  {
    path: '/',
    element: (
      <AuthAccess>
        <Layout
          withMenu={false}
          withNavbar={true}
          withSubscribeBtn={true}
          white={true}
        />
      </AuthAccess>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: urls.profile(),
        element: <ProfilePage />,
        children: [{ path: ':section', element: <ProfilePage /> }],
      },
      {
        path: urls.practice,
        element: <PracticePage />,
      },
      {
        path: urls.payment(':status', ':plan?'),
        element: <PaymentStatusPage />,
      },
      {
        path: urls.progress,
        element: <ProgressPage />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthAccess>
        <Layout
          customContainer={true}
          withMenu={true}
          withNavbar={false}
          white={true}
        />
      </AuthAccess>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: urls.lesson(':tag', '*'),
        element: <ChatPage />,
      },
      {
        path: urls.vocabLesson(':tag', '*'),
        element: <VocabPage />,
      },
      { path: urls.practiceWords(), element: <WordsPage /> },
      { path: urls.practiceWords('select'), element: <WordsPage /> },
    ],
  },
  {
    path: '/',
    element: <Layout fullWidth={true} withMenu={false} withNavbar={false} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: urls.onboarding(':step?'),
        element: <OnboardingPage />,
      },
    ],
  },
  {
    path: urls.login,
    element: (
      <LoginAccess>
        <Login registration={false} />
      </LoginAccess>
    ),
  },
  {
    path: urls.passwordRecovery,
    element: (
      <LoginAccess>
        <PasswordRecovery />
      </LoginAccess>
    ),
  },
  {
    path: urls.registration,
    element: (
      <LoginAccess>
        <Login registration={true} />
      </LoginAccess>
    ),
  },
  {
    path: urls.contacts,
    element: <ContactsPage />,
  },
  {
    path: '/',
    element: (
      <LoginAccess>
        <Outlet />
      </LoginAccess>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: urls.subscriptions(),
        element: <SubscriptionsPage country="ru" />,
        children: [
          { path: ':section', element: <SubscriptionsPage country="ru" /> },
        ],
      },
      {
        path: urls.trialInfo,
        element: <TrialInfoPage />,
      },
    ],
  },
  {
    path: urls.subscriptions_kz,
    element: (
      <OnboardingProvider>
        <SubscriptionsAccess>
          <SubscriptionsPage country="kz" />
        </SubscriptionsAccess>
      </OnboardingProvider>
    ),
  },
  {
    element: <Layout white withMenu={false} withNavbar={false} />,
    path: '/',
    children: [
      {
        path: urls.demo(':infoBlock?'),
        element: (
          <OnboardingProvider>
            <DemoAccess>
              <DemoPage />
            </DemoAccess>
          </OnboardingProvider>
        ),
      },

      chooseYourLevel,
    ],
  },
  {
    path: urls.confirm,
    element: (
      <ConfirmAccess>
        <ConfirmRegistration />
      </ConfirmAccess>
    ),
  },
  {
    path: '/version',
    element: appVersion,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export const router = createBrowserRouter(routeObjects)
