import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { cn, useMount } from '../../shared/lib/utils.ts'
import { urls } from '../../shared/urls.ts'
import {
  MOCK_ADVANCED_LESSON,
  MOCK_VOCAB_LESSONS,
} from '../../shared/data/practice.ts'
import { Button } from '../../shared/ui/button/button.tsx'
import { usePracticeStore } from './PracticeStore.ts'
import { useSnapshot } from 'valtio'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { ProgressBar, ProgressTags } from './PracticeProgressBar.tsx'
import { PracticeMarketingVPHint } from '../hints/marketingVocabHint.tsx'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import {
  TYPES_TIME_MAP,
  TYPES_WITH_SOUND,
} from '../../shared/data/lessonsTags.ts'

export interface IProgressBlockRects {
  top: number
  left: number
  width: number
}

export const VocabLesson = ({
  img,
  lessonText,
  lessonType,
  lessonTag,
  type,
  tag,
}: {
  img: string
  lessonText: string
  lessonTag: string
  lessonType: AppMessageKeys
  type: 'quick' | 'advanced'
  tag: string
}) => {
  const navigate = useNavigate()
  return (
    <div
      className="relative flex h-fit w-full cursor-pointer gap-12 rounded-16 bg-gray-light p-16"
      onClick={() => {
        type === 'quick'
          ? navigate(urls.vocabLesson(lessonTag), {
              state: { lessonType: 'vocabulary' },
            })
          : navigate(urls.practiceWords('select'))
      }}
    >
      <div className="absolute right-10 top-10 flex flex-col  text-12 font-semibold">
        <div className="flex gap-10 opacity-40">
          {TYPES_WITH_SOUND.includes(tag) && (
            <Icon iconName="headphone" className="size-14" />
          )}
          {
            <FormattedMessage
              id="lessonType"
              values={{
                type: tag,
                count: TYPES_TIME_MAP[tag as keyof typeof TYPES_TIME_MAP],
                min: <FormattedMessage id="min" />,
              }}
            />
          }
        </div>
      </div>
      <div
        className={cn(
          'relative flex size-80 shrink-0 items-center justify-center rounded-full border-2 p-6',
          'border-lesson-icon-border',
        )}
      >
        <div
          className={cn(
            'flex size-full items-center justify-center overflow-hidden rounded-full',
            'bg-white',
          )}
        >
          <img className="w-full" src={img} alt="" />
        </div>
      </div>

      <div className="flex flex-col justify-center">
        <p className="text-14 font-extrabold text-black opacity-30">
          <FormattedMessage id={lessonType} />
        </p>
        <p className="text-18 font-bold">{lessonText}</p>
      </div>
    </div>
  )
}

export const PracticeView = () => {
  const store = usePracticeStore()
  const state = useSnapshot(store.state)
  const wordsCount = state.words.count
  const hints = state.hints
  useMount(() => {
    void store.getPracticeInfo()
  })
  const navigate = useNavigate()
  const intl = useIntl()
  const [progressBlockRects, setProgressBlockRects] =
    useState<IProgressBlockRects | null>(null)

  const progressBlockRef = useRef<HTMLDivElement>(null)

  useMount(() => {
    if (progressBlockRef.current) {
      const rects = progressBlockRef.current.getBoundingClientRect()
      if (rects.top && rects.left && rects.width) {
        setProgressBlockRects({
          top: rects.top + window.scrollY,
          left: rects.left,
          width: rects.width,
        })
      }
    }
  })

  if (state.words.loading)
    return (
      <div className="flex h-dvh w-full items-center justify-center">
        <Spinner />
      </div>
    )

  return (
    <div className="relative w-full px-16 pb-100 pt-16 sm:mx-auto sm:w-800 xl:pb-0  ">
      <h1 className="text-center text-18 font-bold">
        <FormattedMessage id="practice.header" />
      </h1>
      <Button
        size="small"
        rounded="full"
        bg="blue-gradient-shadow-inset"
        onClick={() => {
          navigate(urls.practiceWords())
        }}
        disabled={state.words.loading}
        className="absolute right-16 top-12 font-nunito-7-condensed"
      >
        {wordsCount}{' '}
        <FormattedMessage id="wordsPlural" values={{ count: wordsCount }} />
      </Button>
      <div className="mt-28 flex h-72 items-center justify-center text-center text-48 font-black">
        {wordsCount}
      </div>
      <Button
        size="small"
        rounded="full"
        bg="transparent"
        className="mx-auto flex gap-6 border-2 border-lesson-icon-border px-16 py-2 font-nunito-7-semicondensed text-12 font-bold text-lesson-icon-border"
        onClick={() => {
          navigate(urls.practiceWords())
        }}
      >
        <Icon iconName="plus" size="xs" />
        <FormattedMessage id="addNewWord" />
      </Button>
      {/* <div className="mt-12 h-fit w-full rounded-12 bg-gray-light p-32 text-center text-gray7">
        <p className="text-18 font-bold">
          <FormattedMessage id="practice.wordStatEmpty" />
        </p>
        <p className="mt-12 text-14">
          <FormattedMessage id="practice.takeFirstLesson" />
        </p>
      </div> */}

      <p className="mt-24 text-center text-14 text-gray4">
        <FormattedMessage
          id="metrics.footerText"
          values={{
            boldText: (
              <span className="font-bold">
                {intl.formatMessage({ id: 'metrics.footerBoldSpan' })}
              </span>
            ),
          }}
        />
      </p>

      <ProgressBar
        className="mt-24 flex h-132 w-full overflow-hidden rounded-12"
        ref={progressBlockRef}
      />
      <ProgressTags className="mt-12 flex w-full justify-center gap-16 rounded-b-18" />

      <MockLessons type="quick" />
      <MockLessons type="advanced" />
      <AnimatePresence mode="wait">
        {progressBlockRects !== null && hints?.marketingPracticeHint && (
          <PracticeMarketingVPHint
            rects={progressBlockRects}
            onEnd={() => {
              void store.deleteMarketingHints()
            }}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

const MockLessons = ({ type }: { type: 'quick' | 'advanced' }) => {
  const MOCK = type === 'quick' ? MOCK_VOCAB_LESSONS : MOCK_ADVANCED_LESSON
  return (
    <>
      <h2 className="mt-24 text-22 font-bold">
        <FormattedMessage
          id={type === 'quick' ? 'quickStart' : 'advancedMode'}
        />
      </h2>
      <div className="mt-6 flex flex-col gap-16">
        {MOCK.map((el, i) => (
          <VocabLesson
            img={el.img}
            lessonText={el.lessonText}
            lessonType={el.lessonType}
            lessonTag={el.lessonTag}
            key={`vocab-lesson-${i}`}
            type={type}
            tag={el.tag}
          />
        ))}
      </div>
    </>
  )
}
