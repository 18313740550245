import { proxy } from 'valtio'
import {
  ChatApi,
  ChatMessage,
  demoLessonTag,
  InfoBlockType,
  LessonStages,
  LessonType,
  ProgramItem,
  TrueOrFalseQuestion,
  ViewMode,
  Vocabulary,
} from '../../shared/api/chatApi.ts'
import { SpeechService } from '../speech/speechService.ts'
import { AuthStore } from '../auth/authStore.ts'
import {
  emptyFn,
  filterMetrics,
  getLast,
  getTextTag,
  IFilteredMetrics,
  nilMap,
  Payload,
  randomElement,
  removeTextTag,
  sortBy,
  startTimer,
  waitFor,
} from '../../shared/lib/utils.ts'
import { WordWithMeaning } from '../exercises/matchWords/matchWordsStore.ts'
import { WriteTheGapsText } from '../exercises/writeTheGaps.tsx'
import { convertToId, toUppercase } from '../../shared/lib/stringUtils.ts'
import { urls } from '../../shared/urls.ts'
import { FillWordTextData } from '../exercises/fillTheGaps/fillTheGaps.tsx'
import { AppStore } from '../whatisnew/appStore.ts'
import { ListeningQuestion } from '../exercises/listeningExercise/listeningExerciseStore.ts'
import { ProgressApi } from '../../shared/api/progressApi.ts'

const responseTextTimeout = 10 * 1000
const responseTexts = [
  'Making up some magic for your answer... Hold on for just a moment',
  'Hang on! Our answer hamsters are running as fast as they can!',
  "Answer loading... In the meantime, here's a virtual high five for your patience! ✋",
  'Our answer chefs are cooking up something special. Please wait a bit...',
  "Brewing a fresh cup of reply. It'll be worth the wait, we promise!",
  "Just a sec, we're teaching our robots to talk faster!",
  "We're fishing for your response... Stay put!",
  'Mixing up a reply cocktail. Shaken, not stirred, and coming right up!',
  'Your answer is about to land 🛬',
]

const VOCABULARY_HINTS: NonNullable<HintsType>[] = ['allWords', 'clickWord']
const ORTHOGRAPHY_HINT: NonNullable<HintsType>[] = ['orthography_hint']
type LoadingState = 'chat' | 'response' | 'record' | 'analyze' | 'command'

export type PlayBtnState = 'load' | 'play' | 'pause'
export interface ChatMessageWithIndex extends ChatMessage {
  index: number
  text: string
  statePlayBtn: PlayBtnState
  content?: MessageContent
  stage?: string
  showTranslation?: boolean
  showHint?: boolean
  showAiComment?: boolean
}

export interface IDataMessageRequest {
  chat: number
  message_text: string
  exercise_result?: {
    correct: number
    payload: Payload[] | null
    wrongAnswersCount: number | null
  }
  parent_msg?: number
  is_hidden?: boolean
}

export type HintsType = 'clickWord' | 'allWords' | 'orthography_hint' | null

const matchWords = [
  { word: 'Apple', synonym: 'A fruit' },
  { word: 'Dog', synonym: 'An animal' },
  { word: 'Car', synonym: 'A vehicle' },
  { word: 'Table', synonym: 'A furniture' },
  { word: 'Hammer', synonym: 'An instrument' },
]

const fillTheGaps: FillWordTextData[] = [
  {
    text: ['I cannot wait to', { word: 'take' }, 'a bite'],
    options: ['take', 'walk', 'jump'],
    incorrect: '',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    title: 'Fill the gaps',
  },
  {
    text: ['The', { word: 'cat' }, 'jumped over the fence'],
    options: ['cat', 'house', 'bird'],
    incorrect: '',
    title: 'Fill the gaps',
  },
  {
    text: ['She', { word: 'loves' }, 'to read books'],
    options: ['loves', 'speak', 'eat'],
    incorrect: '',
    title: 'Fill the gaps',
  },
  {
    text: ['The sun', { word: 'rises' }, 'in the east'],
    options: ['rises', 'sets', 'shines'],
    incorrect: '',
    title: 'Fill the gaps',
  },
  {
    text: ['He', { word: 'plays' }, 'the guitar every day'],
    options: ['plays', 'sings', 'dances'],
    incorrect: '',
    title: 'Fill the gaps',
  },
]

export const fillWords = [
  ['I cannot wait to take a bite out of this juicy', { word: 'Apple' }, '.'],
  ['My', { word: 'Dog' }, 'is so excited to go for a walk. '],
  ['I love riding in the', { word: 'Car' }, 'with the windows down'],
]

const trueOrFalse = [
  { title: 'The sky is blue.', isCorrect: true },
  { title: 'Cats can fly.', isCorrect: false },
  {
    title: 'Water boils at 100 degrees Celsius at sea level.',
    isCorrect: true,
  },
  { title: 'The Earth is flat.', isCorrect: false },
  { title: 'Humans have walked on the Moon.', isCorrect: true },
]

const writeTheGaps = [
  [
    'She ',
    {
      hint: 'usual',
      word: 'usually',
    },
    ' goes to the gym on Mondays.',
  ],
  [
    'He always ',
    {
      hint: 'do',
      word: 'does',
    },
    ' his homework after dinner.',
  ],
  [
    'My brother ',
    {
      hint: 'work',
      word: 'works',
    },
    ' in a bank.',
  ],
]

export type MessageContent =
  | { image: string }
  | { text: string }
  | { article: { title: string; body: string } }
  | { trueOrFalse: readonly TrueOrFalseQuestion[] }
  | { fillTheGaps: readonly FillWordTextData[] }
  | { listening: readonly ListeningQuestion[] }
  | { matchWords: readonly WordWithMeaning[] }
  | { writeTheGaps: readonly WriteTheGapsText[] }

export function isArticle(content: MessageContent | undefined) {
  if (!content) {
    return false
  }
  return 'article' in content
}
export function isExercise(content: MessageContent | undefined) {
  if (!content) {
    return false
  }
  return (
    'trueOrFalse' in content ||
    'listening' in content ||
    'fillTheGaps' in content ||
    'fillWords' in content ||
    'matchWords' in content ||
    'writeTheGaps' in content
  )
}

export function isText(
  content: MessageContent | undefined,
): content is { image: string } {
  if (!content) {
    return false
  }
  return 'text' in content
}

export function isImage(
  content: MessageContent | undefined,
): content is { image: string } {
  if (!content) {
    return false
  }
  return 'image' in content
}

interface State {
  exerciseResultText?: string
  correctAnswersCount?: number | null
  wrongAnswersCount?: number | null
  payload?: Payload[] | null
  lessonType: LessonType
  lastMessage: ChatMessageWithIndex
  languageLevel?: 'A2' | 'B1' | 'B2'
  userId?: number
  url?: string
  lastMessageId: number | undefined
  //   onlyTextInput: boolean
  program?: ProgramItem
  isDemo: boolean
  completed: boolean
  lessonCompleted: boolean
  speakRussian: boolean
  vocabulary: Vocabulary[]
  lastAddVocabWord: string | null
  selectedWords: { word: string; dark: boolean }[]
  showHistory: boolean
  showMessageTexts: boolean
  infoBlock?: InfoBlockType
  messages: ChatMessageWithIndex[]
  loading?: LoadingState
  programTag: string
  nextProgramTag: string | null
  contentModal: boolean
  stages?: LessonStages
  currentStage?: string
  responseLoadingText?: string // TODO replace with matching
  currentText: string
  chatId: number
  loadingMessage: ChatMessageWithIndex | undefined
  showLoadingMessage: boolean
  sendButtonDisabled: boolean
  disabledByLoading: boolean
  chatInputUnavailable: boolean
  isExercise: boolean
  inputDisabled: boolean
  showChatBlock: boolean
  showTextInput: boolean
  mute: boolean
  statistics: {
    isLessonCompletedPage: boolean
    isStatPage: boolean
    isLoading: boolean
    metrics: IFilteredMetrics | null
    username: string | null
  }
  playingMessage?: {
    messageId: number
    progress: number
  }
  lessonRate: null | 1 | 2 | 3
  addWordDrawer: {
    isOpen: boolean
    word: string
    shouldAdd: boolean
    messageId: number | null
  }
  shouldViewHint: boolean
  shouldViewMicroTooltip: boolean
  currentHint: HintsType
  availableHints: NonNullable<HintsType>[]
  viewMode: ViewMode
}

const defaultState = (chatId: number): State => ({
  get lastMessage() {
    return (
      this.messages[this.messages.length - 1] ?? {
        id: 1,
        message_text: '',
        text: '',
        is_ai: true,
        is_last: false,
        statePlayBtn: 'play',
      }
    )
  },
  get selectedWords() {
    return this.vocabulary.map((x) => ({
      word: x.word_original,
      dark: x.is_user_added,
    }))
  },
  get speakRussian() {
    if (this.languageLevel == 'A2') {
      return true
    }
    if (!this.program) {
      return false
    }
    return (
      this.program.level == 'A2' &&
      this.program.lesson_type == 'General conversation'
    )
  },
  get url() {
    if (this.isDemo) {
      return nilMap(this.lastMessage.state, (x) => urls.demo(convertToId(x)))
    }

    if (!this.currentStage) {
      return undefined
    }
    const id = convertToId(this.currentStage)
    return urls.lesson(this.programTag, id)
  },
  mute: false,
  showTextInput: false,
  get lastMessageId() {
    return getLast(this.messages)?.id
  },
  //   get onlyTextInput() {
  //     return this.infoBlock == 'demo_username'
  //   },
  showChatBlock: false,
  contentModal: false,
  get isDemo() {
    return this.programTag == demoLessonTag
  },
  vocabulary: [],
  lastAddVocabWord: null,
  programTag: '',
  nextProgramTag: null,
  get completed() {
    return this.program?.status == 'completed'
  },
  get lessonCompleted() {
    return !!getLast(this.messages)?.is_last
  },
  showMessageTexts: true,
  showHistory: false,
  messages: [],
  currentText: '',
  chatId,

  get loadingMessage() {
    const newMessage: ChatMessageWithIndex = {
      index: this.messages.length,
      message_text: '',
      text: '',
      is_ai: this.loading == 'response',
      id: -1,
      is_last: false,
      statePlayBtn: 'play',
    }

    return this.showLoadingMessage ? newMessage : undefined
  },

  get showLoadingMessage() {
    const showLoadingMessageStates: LoadingState[] = ['response', 'analyze']
    return this.loading
      ? showLoadingMessageStates.includes(this.loading)
      : false
  },

  get isExercise() {
    const lastMessage = getLast(this.messages)
    return isExercise(lastMessage?.content)
  },
  get chatInputUnavailable() {
    return this.isExercise || this.lessonCompleted
  },

  get disabledByLoading() {
    const completeButtonDisabledStates: LoadingState[] = [
      'response',
      'analyze',
      'command',
    ]
    return !!this.loading && completeButtonDisabledStates.includes(this.loading)
  },

  get sendButtonDisabled() {
    return this.disabledByLoading || this.chatInputUnavailable
  },
  get inputDisabled() {
    const lastMessage = getLast(this.messages)
    return this.lessonCompleted || isExercise(lastMessage?.content)
  },
  statistics: {
    isLessonCompletedPage: false,
    isStatPage: false,
    isLoading: false,
    metrics: null,
    username: null,
  },
  addWordDrawer: {
    isOpen: false,
    word: '',
    shouldAdd: false,
    messageId: null,
  },
  get lessonRate() {
    const lastMessage = getLast(this.messages)
    return lastMessage?.ai_rate?.rate ?? null
  },
  currentHint: null,
  shouldViewHint: false,
  shouldViewMicroTooltip: false,
  availableHints: [],
  lessonType: null,
  viewMode: 'full',
})
export class ChatStore {
  state: State

  constructor(
    private chatApi: ChatApi,
    private authStore: AuthStore,
    private speechService: SpeechService,
    private appStore: AppStore,
    private progressApi: ProgressApi,
  ) {
    this.state = proxy<State>(defaultState(0))
  }

  async updateStage() {
    if (this.state.programTag) {
      const stage = await this.chatApi.userProgramStage(this.state.programTag)
      this.state.currentStage = stage.stage
    }
  }

  goToLessonCompletedPage() {
    this.state.statistics.isLessonCompletedPage = true
    if (this.state.isDemo) {
      this.authStore.trackOnbMetrika('demo_finished')
    }
  }

  async goToLessonStat() {
    if (!this.state.programTag) return
    this.state.statistics.isStatPage = true
    this.state.statistics.isLoading = true

    // const data = await this.progressApi.getCurrentLessonMetrics(
    //   this.state.programTag,
    // )
    const data = await this.progressApi.getMetrics()
    const { name: username } = await this.chatApi.getAccount()

    this.state.statistics = {
      isLessonCompletedPage: false,
      isLoading: false,
      isStatPage: true,
      metrics: filterMetrics(data, this.state.programTag),
      username,
    }
  }

  resetLessonStat() {
    this.state.statistics = {
      isLessonCompletedPage: false,
      isStatPage: false,
      isLoading: false,
      metrics: null,
      username: null,
    }
  }

  async newChat(
    tag: string,
    recreate = false,
    lessonType: LessonType = 'lesson',
    viewMode: ViewMode = 'full',
  ) {
    if (!this.authStore.state.user) {
      return
    }
    try {
      this.state.programTag = tag
      this.resetState()
      this.resetLessonStat()
      this.setLoading('chat')
      this.speechService.init()
      await this.updateLanguageLevel()
      this.state.showChatBlock = true
      this.setLessonType(lessonType)
      this.setViewMode(viewMode)
      void this.chatApi.patchAccount({
        session_metadata: {
          programTag: this.state.programTag,
          lessonType,
        },
      })
      const [chat] = await Promise.all([
        this.chatApi.chat_create({
          user: this.authStore.state.user.user_id,
          program_tag: tag,
          chat_title: tag,
          language: 'EN',
          recreate,
        }),
        this.updateLanguageLevel(),
      ])

      this.state.nextProgramTag = chat.next_program_tag
      await this.load(chat.id)
    } finally {
      this.state.loading = undefined
    }
  }

  async loadStages() {
    this.state.stages = await this.chatApi.userProgramStages(
      this.state.programTag,
    )
    await this.updateStage()
  }

  get currentTextIsEmpty() {
    return !this.state.currentText
  }

  removeLastMessage() {
    this.state.messages.pop()
  }

  setStatePlayBtnMessage = (messageId: number, state: PlayBtnState) => {
    const updatedMessages = this.state.messages.map((message) => {
      if (message.id === messageId) {
        return { ...message, statePlayBtn: state }
      }

      if (message.statePlayBtn === 'pause' && message.id !== messageId) {
        message.statePlayBtn = 'play'
        return message
      }

      return message
    })

    this.state.messages = updatedMessages
  }

  resetStatePlayBtnMessage = () => {
    const updatedMessages = this.state.messages.map((message) => {
      message.statePlayBtn = 'play'
      return message
    })

    this.state.messages = updatedMessages
  }

  //   addDemoLevelInfoBlock() {
  //     this.state.infoBlock = 'demo_level'
  //     this.state.loading = undefined
  //   }

  setLoading(loading: LoadingState) {
    this.state.loading = loading
  }

  async waitForFirstMessage() {
    await waitFor(() => this.state.messages.length > 0, 100)
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  setCurrentContent(message: ChatMessageWithIndex) {
    if (message.picture_url) {
      message.content = { image: message.picture_url }
      this.authStore.trackMetrika('demo2_photo_received')
      if (this.state.isDemo) {
        this.authStore.trackMetrika('picture_demo_sent')
      }
    }
    if (message.article) {
      message.content = {
        article: {
          title: message.article.header,
          body: message.article.body,
        },
      }
    }
    if (message.true_or_false) {
      message.content = { trueOrFalse: message.true_or_false }
    }
    if (message.listening) {
      message.content = {
        listening: message.listening.map((x) => ({
          ...x,
          soundUrl: x.sound_url,
        })),
      }
    }
    if (message.fill_the_gaps2) {
      const language = toUppercase(this.appStore.state.language)

      message.content = {
        fillTheGaps: message.fill_the_gaps2.map((x) => ({
          incorrect: x.incorrect[language],
          title: x.title[language],
          options: x.options,
          text: x.text,
        })),
      }
    }
    if (message.match_words) {
      message.content = { matchWords: message.match_words }
    }
    if (message.write_the_gaps) {
      message.content = { writeTheGaps: message.write_the_gaps }
    }
    if (message.info_block && message.info_block === 'start_vocabulary_hint') {
      this.state.availableHints = VOCABULARY_HINTS
    }
    if (message.info_block && message.info_block === 'orthography_hint') {
      this.state.availableHints = ORTHOGRAPHY_HINT
    }
    if (message.info_block && message.info_block === 'first_message') {
      this.state.shouldViewMicroTooltip = true
    }

    const textTag = getTextTag(message.message_text)
    if (textTag) {
      message.content = { text: textTag }
    }
  }
  addMessage(message: ChatMessage, fromLoad = false) {
    const text = removeTextTag(message.message_text)

    const newMessage: ChatMessageWithIndex = {
      ...message,
      text: text,
      index: this.state.messages.length,
      statePlayBtn: 'play',
    }
    if (fromLoad) {
      newMessage.ai_comment = message.ai_comment ?? []
    }
    this.setCurrentContent(newMessage)
    if (!newMessage.is_hidden) {
      this.state.messages.push(newMessage)
    }

    if (
      this.state.languageLevel == 'A2' &&
      newMessage.is_ai &&
      newMessage.translation
    ) {
      this.state.lastMessage.showTranslation = true
    }
    return newMessage
  }

  resetState() {
    this.state.messages = []
    this.state.infoBlock = undefined
  }

  async updateLanguageLevel() {
    const account = await this.chatApi.getAccount()
    this.setLanguageLevel(account.level)
  }

  async loadProgram() {
    if (this.state.isDemo) {
      return
    }
    const programs = await this.chatApi.userPrograms()
    this.state.program = programs.find((x) => x.tag == this.state.programTag)
  }
  async load(chatId: number) {
    if (this.state.chatId == chatId) {
      return
    }

    this.authStore.trackMetrika('demo2_lesson_launched')

    this.state.chatId = chatId
    this.setLoading('chat')

    const [messages] = await Promise.all([
      this.chatApi.message_list(this.state.chatId),
      this.loadProgram(),
      this.loadStages(),
      this.loadWords(),
    ])
    for (const m of sortBy(messages, (x) => x.id)) {
      this.addMessage(m, true)
    }
    if (this.state.messages.length == 1) {
      await this.play(this.state.messages[0].id, false)
    }

    this.state.loading = undefined
    if (this.authStore.state.user) {
      this.state.userId = this.authStore.state.user.user_id
    }
    if (this.state.isDemo) {
      this.authStore.trackOnbMetrika('1st_question')
    }
  }

  async sendCurrentText() {
    if (this.state.loading) {
      return
    }
    const text = this.state.currentText
    this.state.currentText = ''
    await this.send(text)
  }

  handleCommand(text: string) {
    if (text == '/text') {
      this.state.lastMessage.content = {
        text: 'Tom is in France on his first trip. He goes to a big supermarket to find food. He wants to buy milk, water, and some French cheese. Tom takes a basket and starts looking for the dairy section. He sees many types of cheese and picks one that looks tasty. ',
      }
    }
    if (text == '/end') {
      this.endChat()
    }
    if (text == '/fillgaps') {
      this.state.lastMessage.content = { fillTheGaps }
      this.state.showTextInput = false
    }
    if (text == '/task1') {
      this.state.lastMessage.content = { trueOrFalse }
      this.state.showTextInput = false
    }
    if (text == '/task2') {
      this.state.lastMessage.content = { matchWords }
    }
    if (text == '/task3') {
      this.state.lastMessage.content = { writeTheGaps }
    }
    if (text == '/image') {
      this.state.lastMessage.content = {
        image:
          'https://img.dev.edman.ai/278/20240322/49b5e6fc-0a50-4faf-83bd-c10b2a94e906.png',
      }
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async send(
    text: string,
    hidden = false,
    correctAnswersCount: number | null = null,
    payload: Payload[] | null = null,
    wrongAnswersCount: number | null = null,
  ) {
    if (Boolean(this.state.loading) || !text) {
      return
    }
    if (text.startsWith('/')) {
      this.handleCommand(text)
      return
    }

    const stopResponseTextTimeout = this.startResponseTextTimeout()
    try {
      this.state.loading = 'response'

      await this.waitForFirstMessage()

      if (isImage(this.state.lastMessage.content)) {
        this.authStore.trackMetrika('demo2_photo_answered')
        if (this.state.isDemo) {
          this.authStore.trackMetrika('picture_demo_answer')
        }
      }

      if (isText(this.state.lastMessage.content)) {
        this.authStore.trackMetrika('demo2_text_answered')
        if (this.state.isDemo) {
          this.authStore.trackMetrika('picture_demo_answer')
        }
      }
      const lastMessage = this.state.lastMessage
      const fakeUserMessageId = lastMessage.id + 1
      const userMessage = this.addMessage({
        is_ai: false,
        message_text: text,
        is_hidden: hidden,
        id: fakeUserMessageId,
        is_last: false,
      })

      //   if (this.state.isDemo && this.state.infoBlock) {
      //     this.authStore.trackMetrika(`${this.state.infoBlock}_answer`)
      //     if (this.state.infoBlock == 'demo_subtopic') {
      //       this.state.showChatBlock = true
      //       this.state.messages = []
      //     }
      //     this.state.infoBlock = undefined
      //   }
      const getData = () => {
        const data: IDataMessageRequest = {
          chat: this.state.chatId,
          message_text: text,
          is_hidden: hidden,
        }
        if (
          Number.isInteger(correctAnswersCount) &&
          correctAnswersCount !== null
        ) {
          data.exercise_result = {
            correct: correctAnswersCount,
            payload: null,
            wrongAnswersCount: null,
          }
          data.parent_msg = this.state.lastMessageId
        }
        if (payload !== null && data.exercise_result) {
          data.exercise_result.payload = payload
        }
        if (wrongAnswersCount !== null && data.exercise_result) {
          data.exercise_result.wrongAnswersCount = wrongAnswersCount
        }
        return data
      }
      const timer = startTimer()
      const realUserMessageId = (await this.chatApi.message_create(getData()))
        .id
      userMessage.id = realUserMessageId
      const newMessagesPromise = this.chatApi.message_process(realUserMessageId)
      const orthographyCheckPromise = hidden
        ? Promise.resolve(undefined)
        : this.chatApi.message_orthography_check(realUserMessageId)

      const [newMessages, orthographyCheck] = await Promise.all([
        newMessagesPromise,
        orthographyCheckPromise,
      ])
      stopResponseTextTimeout()
      for (const newMessage of newMessages) {
        void this.chatApi.patchMessage(newMessage.id, {
          duration_front: timer.stop(),
        })

        this.addMessage(newMessage)
        orthographyCheck && this.updateAiComment(orthographyCheck)
        void this.loadWords()
        void this.updateStage()

        this.state.loading = undefined
        await this.play(newMessage.id, false)
      }
    } catch {
      this.removeLastMessage()
    } finally {
      stopResponseTextTimeout()
      this.state.loading = undefined
      if (this.state.lessonCompleted) {
        this.authStore.trackMetrika('demo2_lesson_finished')
      }
    }
  }

  updateAiComment(message: ChatMessage) {
    const updateMessage = this.state.messages.find((x) => x.id == message.id)
    if (updateMessage) {
      updateMessage.ai_comment = message.ai_comment ?? []
    }
  }

  toggleMessageAiComment(id: number) {
    const message = this.state.messages.find((x) => x.id == id)
    if (message) {
      message.showAiComment = !message.showAiComment
    }
  }

  async toggleMessageTranslate(id: number) {
    const message = this.state.messages.find((x) => x.id == id)
    if (message) {
      if (message.showTranslation) {
        message.showTranslation = false
      } else {
        message.showTranslation = true
        const translation = await this.chatApi.message_translate(id)
        message.translation = translation.translation
      }
    }
  }

  async showMessageHint() {
    const id = this.state.lastMessage.id
    const message = this.state.messages.find((x) => x.id == id)
    if (message) {
      message.showHint = true
      if (!message.reply_hint) {
        const translation = await this.chatApi.message_reply_hint(id)
        message.reply_hint = translation.reply_hint
      }
    }
  }

  async play(messageId: number, forcePlay: boolean, hint = false) {
    const message = this.state.messages.find((x) => x.id == messageId)
    if (!message || (this.state.mute && !forcePlay)) {
      if (this.state.availableHints.includes('clickWord')) {
        setTimeout(() => {
          this.setShouldViewHint(true, 'clickWord')
        }, 3000)
      }
      if (this.state.availableHints.includes('orthography_hint')) {
        this.setShouldViewHint(true, 'orthography_hint')
      }
      return
    }
    try {
      this.setStatePlayBtnMessage(message.id, 'load')
      if (!forcePlay) {
        this.state.loading = 'command'
      }
      const text = hint ? message.reply_hint?.text : message.text
      if (!text) {
        return
      }
      const id = hint ? -messageId : messageId
      await this.speechService.playText(
        id,
        this.state.chatId,
        text,
        (progress) => {
          this.state.playingMessage = {
            messageId: message.id,
            progress,
          }
        },
        () => {
          this.setStatePlayBtnMessage(message.id, 'pause')
        },
        () => {
          this.setStatePlayBtnMessage(message.id, 'play')
        },
      )
    } finally {
      if (!forcePlay) {
        this.state.loading = undefined
      }
      if (this.state.availableHints.includes('clickWord')) {
        this.setShouldViewHint(true, 'clickWord')
      }
      if (this.state.availableHints.includes('orthography_hint')) {
        this.setShouldViewHint(true, 'orthography_hint')
      }
    }
  }

  startResponseTextTimeout() {
    const id = setTimeout(() => {
      this.state.responseLoadingText = randomElement(responseTexts)
    }, responseTextTimeout)
    return () => {
      this.state.responseLoadingText = undefined
      clearTimeout(id)
    }
  }

  setCurrentText(text: string) {
    this.state.currentText = text
  }

  endChat() {
    this.state.lastMessage.is_last = true
  }

  async loadWords() {
    if (this.state.programTag) {
      const vocabulary = await this.chatApi.vocabulary(this.state.programTag)
      this.state.vocabulary = vocabulary
    }
  }

  async removeWord(id: number) {
    this.state.vocabulary = this.state.vocabulary.filter((x) => x.id != id)
    await this.chatApi.vocabulary_delete(id)
  }

  async handleWord() {
    if (this.state.addWordDrawer.shouldAdd) {
      await this.addToDictionary(this.state.addWordDrawer.word)
      if (this.state.availableHints.includes('allWords')) {
        this.setShouldViewHint(true, 'allWords')
      }
    } else {
      const wordId = this.state.vocabulary.find(
        (x) => x.word_original == this.state.addWordDrawer.word,
      )?.id
      if (wordId) {
        await this.removeWord(wordId)
      }
    }
  }
  async addToDictionary(word: string) {
    const newWord: Vocabulary = {
      id: 0,
      word_original: word,
      word_normal: word,
      timestamp: '',
      program_tag: '',
      is_user_added: true,
    }
    this.state.vocabulary.push(newWord)
    const index = this.state.vocabulary.length - 1

    //this.playWord(word)
    const message_id = this.state.addWordDrawer.messageId

    if (message_id) {
      this.setLastAddVocabWord(word)
      const updateWord = await this.chatApi.vocabulary_add({
        chat_id: this.state.chatId,
        word_original: word,
        message_id,
      })
      this.state.vocabulary[index] = updateWord
    }
  }

  playWord(word: string) {
    void this.speechService.playText(
      undefined,
      this.state.chatId,
      word,
      emptyFn,
    )
  }

  onExerciseComplete(
    result: string,
    correctAnswersCount?: number,
    payload?: Payload[],
    wrongAnswersCount?: number | null,
  ) {
    this.authStore.trackMetrika('demo2_puzzle_passed')
    this.state.exerciseResultText = result

    if (Number.isInteger(correctAnswersCount)) {
      this.state.correctAnswersCount = correctAnswersCount
    }
    if (Number.isInteger(wrongAnswersCount)) {
      this.state.wrongAnswersCount = wrongAnswersCount
    }
    if (payload) {
      this.state.payload = payload
    }
    this.continueAfterExercise()
  }

  continueAfterExercise() {
    if (!this.state.exerciseResultText) {
      return
    }

    void this.send(
      this.state.exerciseResultText,
      true,
      this.state.correctAnswersCount,
      this.state.payload,
      this.state.wrongAnswersCount,
    )
    this.state.exerciseResultText = undefined
    this.state.correctAnswersCount = null
    this.state.wrongAnswersCount = null
    this.state.payload = null
  }

  setContentModal(contentModal: boolean) {
    this.state.contentModal = contentModal
  }

  toggleMute() {
    this.state.mute = !this.state.mute
    this.stopEdmanAudio()
    this.resetStatePlayBtnMessage()
  }
  setShowTextInput(showTextInput: boolean) {
    this.state.showTextInput = showTextInput
  }

  stopEdmanAudio() {
    this.speechService.stopCurrentSound()
  }

  disposeEdmanAudio() {
    this.speechService.dispose()
  }

  rateChat(rating?: number, experience?: number, user_comment?: string) {
    return this.chatApi.chat_rate(
      this.state.chatId,
      rating,
      experience,
      user_comment,
    )
  }
  setLanguageLevel(level: 'A2' | 'B1' | 'B2') {
    this.state.languageLevel = level
  }

  handleOpenAddDrawer(word: string, shouldAdd: boolean, messageId: number) {
    this.state.addWordDrawer = { isOpen: true, word, shouldAdd, messageId }
  }

  handleCloseAddDrawer() {
    this.state.addWordDrawer = {
      isOpen: false,
      word: '',
      shouldAdd: false,
      messageId: null,
    }
  }

  setCurrentHint(hint: HintsType) {
    this.state.currentHint = hint
    if (hint !== null) {
      this.state.availableHints = this.state.availableHints.filter(
        (el) => el !== hint,
      )
    }
  }

  setShouldViewHint(should: boolean, hint?: NonNullable<HintsType>) {
    if (should && hint) {
      this.state.shouldViewHint = true
      this.setCurrentHint(hint)
    } else if (!should && this.state.shouldViewHint) {
      this.state.shouldViewHint = false
      this.setCurrentHint(null)
    }
  }

  setShouldViewMicroTooltip(should: boolean) {
    this.state.shouldViewMicroTooltip = should
  }

  setLessonType(type: LessonType) {
    this.state.lessonType = type
  }

  setViewMode(mode: ViewMode) {
    this.state.viewMode = mode
  }

  setLastAddVocabWord(word: string | null) {
    this.state.lastAddVocabWord = word
  }

  trackPlayMetrika() {
    this.authStore.trackMetrika('tts_click')
  }
}
