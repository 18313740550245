import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { FormattedMessage } from 'react-intl'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { cn, useMount } from '../../shared/lib/utils.ts'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '../../shared/ui/drawer/drawer.tsx'
import { Button } from '../../shared/ui/button/button.tsx'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { motion, useAnimation, useMotionValue } from 'framer-motion'
import { useSnapshot } from 'valtio'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { Filters, useWordsStore } from './wordsStore.ts'
import { TransparentGradient } from '../../shared/ui/gradient/TransparentGradient.tsx'
import { Vocabulary } from '../../shared/api/chatApi.ts'
import { urls } from '../../shared/urls.ts'
import Checkbox from '../../shared/ui/checkbox/checkbox.tsx'
import { classed } from '@tw-classed/react'
import { ADVANCED_LESSON_TAG } from '../../shared/data/practice.ts'

const TABS: { text: AppMessageKeys; selector: Filters; withCount: boolean }[] =
  [
    { text: 'words.all', selector: 'all', withCount: true },
    { text: 'words.byDate', selector: 'byDate', withCount: false },
    { text: 'words.added', selector: 'added', withCount: false },
  ]

const BUTTON_OFFSET = 80

const ClassedTab = classed.div(
  'whitespace-nowrap rounded-full px-16 py-4 font-nunito-7-semicondensed text-16 font-bold transition-all',
  {
    variants: {
      isActive: {
        true: 'bg-user-message text-black',
        false: 'bg-gray-light text-gray7',
      },
    },
  },
)

const AddedTab = classed.div(
  'absolute right-24 top-1/2 -translate-y-1/2 rounded-8 border-1 border-yellow3 bg-yellow4 px-12 py-2 font-nunito-7-semicondensed text-12 font-bold text-yellow2',
)

const DeleteButton = classed.div(
  'absolute inset-y-0 right-0 flex h-80 w-half items-center justify-end bg-[#FD473D]',
  {
    variants: {
      isFirstElem: {
        true: 'rounded-t-16',
        false: '',
      },
      isLastElem: {
        true: 'rounded-b-16',
        false: '',
      },
    },
  },
)

export function WordsView() {
  const navigate = useNavigate()
  const store = useWordsStore()
  const state = useSnapshot(store.state)
  const location = useLocation()
  const isSelectPage = location.pathname.endsWith('select')
  const currentTabSelector = state.filter.selector
  const wordsCounter = state.checkedWords.length

  useMount(() => {
    void store.getStat()
    void store.getAllWords()
    store.initSpeechService()
  })
  return (
    <div className="w-full pb-[152px] sm:mx-auto sm:mt-40 sm:max-w-1000 sm:rounded-t-24 sm:bg-white">
      <div className="sticky top-0 z-20 flex w-full flex-col items-center justify-center  bg-white py-16">
        <button
          onClick={() => {
            navigate(-1)
            isSelectPage && store.deselectWords()
          }}
          className="absolute left-16 top-16"
        >
          <Icon iconName="left" />
        </button>
        <h3 className="text-18 font-bold">
          <FormattedMessage id={isSelectPage ? 'selectWords' : 'words'} />
        </h3>
        {isSelectPage && (
          <div className="sticky top-0  mt-24 flex w-[calc(100%-32px)] items-center justify-between rounded-18 bg-gray-light px-16 py-12">
            <p className="text-14 font-bold">
              <FormattedMessage id="Choose5Words" />
            </p>
            <div className="flex items-center gap-8">
              <p className="text-14 font-semibold text-gray4">
                {wordsCounter}/5
              </p>
              <CircularProgress progressIndex={wordsCounter} />
            </div>
          </div>
        )}
        <div className="no-scrollbar mt-32 flex min-w-full max-w-full gap-8 overflow-x-auto px-16">
          {!state.words.loading &&
            TABS.map((el, i) => (
              <Tab
                key={`tab-key-${i}`}
                text={
                  <FormattedMessage
                    id={el.text}
                    values={el.withCount ? { count: state.totalWords } : {}}
                  />
                }
                isActive={el.selector === currentTabSelector}
                handler={() => {
                  store.setFilter(el.selector)
                }}
              />
            ))}
        </div>
        <TransparentGradient direction="bottom" />
      </div>

      <div>
        {state.words.loading ? (
          <div className="mt-100 flex size-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <WordBlock
            // title={
            //   <p className="font-bold text-16 text-gray-dark">New words(8)</p>
            // }
            words={state.words.list}
          />
        )}
      </div>
      <div className="fixed bottom-0 z-30 w-full bg-white px-16 pb-24 pt-12 sm:max-w-1000">
        <TransparentGradient direction="top" />
        <div
          className={cn(
            'flex gap-8',
            isSelectPage ? 'flex-col' : 'flex-col-reverse',
          )}
        >
          <StartButton isSelectPage={isSelectPage} />
          <AddToWordsDrawer isSelectPage={isSelectPage} />
        </div>
      </div>
    </div>
  )
}

export function Tab({
  isActive,
  text,
  handler,
}: {
  isActive: boolean
  text: React.ReactNode
  handler?: () => void
}) {
  return (
    <ClassedTab isActive={isActive} onClick={handler}>
      {text}
    </ClassedTab>
  )
}

export function WordBlock({
  //title,
  words,
}: {
  //title: ReactNode
  words: readonly Vocabulary[] | null
}) {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  if (!words) return null

  return (
    <motion.div
      className="mt-16 flex flex-col gap-8 px-16"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      <div className="flex">
        {/*isSelectPage && <Checkbox className="ml-14 mr-12" />}
        { {title} */}
      </div>
      <div className="flex flex-col">
        {words.map((el, i) => (
          <WordBlockItem
            el={el}
            index={i}
            key={`${el.id}-${i}`}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
            isLastElement={i === words.length - 1}
          />
        ))}
      </div>
    </motion.div>
  )
}

const WordBlockItem = ({
  el,
  index,
  openIndex,
  setOpenIndex,
  isLastElement,
}: {
  el: Vocabulary
  index: number
  openIndex: number | null
  setOpenIndex: Dispatch<SetStateAction<number | null>>
  isLastElement: boolean
}) => {
  const store = useWordsStore()
  const state = useSnapshot(store.state)
  const x = useMotionValue(0)
  const controls = useAnimation()

  const isUserAdded = el.is_user_added
  const isSelected = state.checkedWords.includes(el.word_normal)
  const location = useLocation()
  const isSelectPage = location.pathname.endsWith('select')
  const playingWordId = state.playingWordId

  const getIsDrag = (isUserAdded: boolean) => {
    if (isSelectPage) return false
    return isUserAdded ? 'x' : false
  }

  const handleDelete = (id: number) => {
    //setWords((prev) => prev.filter((_, i) => i !== index));
    if (openIndex === id) {
      setOpenIndex(null)
      void store.deleteWord(id)
    }
  }

  const handlePlay = (word: string, wordId: number) => {
    if (playingWordId) return
    store.playWord(word, wordId)
  }

  const handleToggleCheckWord = (word: string) => {
    store.toggleCheckedWords(word)
  }

  useEffect(() => {
    if (openIndex !== el.id) {
      void controls.start({
        x: 0,
        transition: {
          type: 'spring',
          stiffness: 50,
          damping: 10,
        },
      })
    }
  }, [openIndex, el.id, controls])

  return (
    <motion.div
      key={el.id}
      className="relative"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.3 }}
      onClick={(e) => {
        e.stopPropagation()
        setOpenIndex(el.id)
      }}
    >
      <DeleteButton isFirstElem={index === 0} isLastElem={isLastElement}>
        <button
          onClick={() => {
            handleDelete(el.id)
          }}
          className="mr-18 flex flex-col items-center text-14 font-bold text-white"
        >
          <Icon iconName="deleteWithCross" size="small" />
          <FormattedMessage id="delete" />
        </button>
      </DeleteButton>

      <motion.div
        className={cn(
          'relative z-10 flex items-start gap-8 p-16',
          index === 0 ? 'rounded-t-16' : '',
          isLastElement ? 'rounded-b-16' : '',
          isSelected ? 'bg-user-message' : 'bg-gray-light',
        )}
        style={{ x, touchAction: 'pan-y' }}
        onClick={() => {
          isSelectPage ? handleToggleCheckWord(el.word_normal) : {}
        }}
        drag={getIsDrag(isUserAdded)}
        dragConstraints={{ left: -BUTTON_OFFSET, right: 0 }}
        dragElastic={0.2}
        onDragEnd={(_, info) => {
          if (info.offset.x < -BUTTON_OFFSET / 2) {
            void controls.start({
              x: -BUTTON_OFFSET,
              transition: {
                type: 'spring',
                stiffness: 50,
                damping: 10,
              },
            })
            setOpenIndex(el.id)
          } else {
            void controls.start({
              x: 0,
              transition: {
                type: 'spring',
                stiffness: 50,
                damping: 10,
              },
            })
            setOpenIndex(null)
          }
        }}
        animate={controls}
      >
        {isSelectPage ? (
          <Checkbox
            className="my-auto -ml-2 mr-4"
            checked={state.checkedWords.includes(el.word_normal)}
          />
        ) : (
          <SpeakerButton
            id={el.id}
            handler={() => {
              handlePlay(el.word_normal, el.id)
            }}
          />
        )}
        <div className="flex-1 font-nunito-7-semicondensed">
          <p className="text-18 font-bold">{el.word_normal}</p>
          <p className="text-14 font-semibold opacity-50">{el.translation}</p>
        </div>
        {el.is_user_added && (
          <AddedTab>
            <FormattedMessage id="added" />
          </AddedTab>
        )}
      </motion.div>
    </motion.div>
  )
}

const SpeakerButton = ({
  id,
  handler,
}: {
  id: number
  handler: () => void
}) => {
  const store = useWordsStore()
  const state = useSnapshot(store.state)
  const playingWordId = state.playingWordId
  return playingWordId === id ? (
    <Spinner className="size-24 text-blue350" />
  ) : (
    <button
      className="cursor-pointer text-blue350"
      onClick={() => {
        handler()
      }}
    >
      <Icon iconName="speaker" />
    </button>
  )
}

function StartButton({ isSelectPage }: { isSelectPage: boolean }) {
  const navigate = useNavigate()
  const store = useWordsStore()
  const wordsState = useSnapshot(store.state)
  const checkedWords = wordsState.checkedWords
  const isDisabled = isSelectPage ? checkedWords.length !== 5 : false
  const handleClick = () => {
    isSelectPage
      ? navigate(urls.vocabLesson(ADVANCED_LESSON_TAG), {
          state: { userVocab: [...checkedWords] },
        })
      : navigate(urls.practiceWords('select'))
  }

  return (
    <Button
      size="extralarge"
      rounded="full"
      bg={isSelectPage ? 'blue-gradient-shadow-inset' : 'transparent'}
      disabled={isDisabled}
      onClick={handleClick}
      className="w-full text-18 font-bold"
    >
      <FormattedMessage id={'startTheGame'} />
    </Button>
  )
}

function AddToWordsDrawer({ isSelectPage }: { isSelectPage: boolean }) {
  const store = useWordsStore()
  const state = useSnapshot(store.state)
  const isLoading = state.addWordDrawer.loading
  const word = state.addWordDrawer.word

  const handleClose = () => {
    store.setAddWordDrawerOpen(false)
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    store.setWord(e.target.value)
  }

  return (
    <Drawer open={state.addWordDrawer.open} onClose={handleClose}>
      <DrawerTrigger asChild>
        <Button
          size="extralarge"
          rounded="full"
          bg={isSelectPage ? 'transparent' : 'blue-gradient-shadow-inset'}
          onClick={() => {
            store.setAddWordDrawerOpen(true)
          }}
          className="w-full text-18 font-bold"
        >
          <FormattedMessage id={'addNewWord'} />
        </Button>
      </DrawerTrigger>
      <DrawerContent direction="bottom" onOverlayClick={handleClose}>
        <div className="relative mx-auto flex h-[95dvh] max-w-800 flex-col rounded-t bg-white px-28">
          <DrawerBar />
          <div className="mt-14 text-20 font-bold">
            <FormattedMessage id="addNewWord" />
          </div>
          <TextInput
            size="extralarge"
            className="mt-32 border-purple3"
            value={state.addWordDrawer.word}
            onChange={handleInput}
          />
          <Button
            size="extralarge"
            rounded="full"
            bg="blue-gradient-shadow-inset"
            className="mt-24 w-full text-18 font-bold"
            disabled={!word || isLoading}
            onClick={() => void store.addWordToVocabulary()}
          >
            {isLoading ? <Spinner /> : <FormattedMessage id="add" />}
          </Button>
          <Button
            size="extralarge"
            rounded="full"
            bg="transparent"
            className="mt-12 w-full text-18 font-bold"
            onClick={handleClose}
          >
            <FormattedMessage id="cancel" />
          </Button>
          <DrawerClose asChild onClick={handleClose}>
            <div className="absolute right-28 top-28 flex size-28 items-center justify-center rounded-full bg-gray">
              <Icon iconName="close" className="text-black" />
            </div>
          </DrawerClose>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

const SEGMENTS = [
  {
    d: 'M18 2.43C18 1.08795 19.0928 -0.0168641 20.4227 0.163769C23.1703 0.536977 25.8061 1.5417 28.1175 3.11255C30.4289 4.6834 32.3334 6.76423 33.6919 9.18149C34.3494 10.3515 33.7244 11.7743 32.4766 12.2683V12.2683C31.2288 12.7623 29.8346 12.1345 29.1195 10.9988C28.1592 9.4737 26.8907 8.15487 25.3858 7.13216C23.8809 6.10945 22.1877 5.41546 20.4162 5.08405C19.097 4.83726 18 3.77205 18 2.43V2.43Z',
  },
  {
    d: 'M32.808 13.1886C34.0843 12.7739 35.4728 13.4718 35.7119 14.7924C36.206 17.5209 36.065 20.3381 35.2853 23.0218C34.5056 25.7056 33.1151 28.1598 31.2359 30.1988C30.3264 31.1856 28.7801 31.031 27.9247 29.9969V29.9969C27.0692 28.9628 27.2355 27.4428 28.0947 26.4118C29.2484 25.0272 30.1106 23.4132 30.6183 21.6659C31.1259 19.9187 31.2627 18.0939 31.0304 16.3067C30.8575 14.9758 31.5316 13.6033 32.808 13.1886V13.1886Z',
  },
  {
    d: 'M27.1518 30.5964C27.9407 31.6821 27.7059 33.2183 26.5239 33.8538C24.0817 35.1669 21.3587 35.9033 18.5654 35.9911C15.7721 36.0789 13.0083 35.5149 10.4884 34.3578C9.2688 33.7977 8.93807 32.2793 9.65718 31.1462V31.1462C10.3763 30.0131 11.8733 29.7015 13.1194 30.2C14.7927 30.8694 16.5941 31.1907 18.4127 31.1335C20.2313 31.0764 22.0091 30.6426 23.637 29.8694C24.8493 29.2937 26.363 29.5107 27.1518 30.5964V30.5964Z',
  },
  {
    d: 'M8.84818 30.5964C8.05935 31.6821 6.52583 31.9336 5.55614 31.0058C3.55265 29.0889 2.01082 26.7268 1.06415 24.0973C0.117477 21.4678 -0.200187 18.665 0.12163 15.9109C0.27739 14.5779 1.61927 13.7941 2.91916 14.1279V14.1279C4.21905 14.4617 4.97797 15.7891 4.88894 17.1282C4.76939 18.9265 5.02049 20.7391 5.63683 22.451C6.25316 24.163 7.21508 25.7196 8.45344 27.029C9.37561 28.004 9.63702 29.5107 8.84818 30.5964V30.5964Z',
  },
  {
    d: 'M3.19205 13.1886C1.91568 12.7739 1.20265 11.3931 1.78539 10.1842C2.98938 7.6864 4.75943 5.4901 6.96767 3.77722C9.17591 2.06433 11.7434 0.896087 14.4621 0.35109C15.778 0.0873105 16.9381 1.12132 17.0223 2.46072V2.46072C17.1066 3.80013 16.0786 4.93212 14.7775 5.26126C13.0304 5.70324 11.3841 6.50218 9.9464 7.61737C8.50871 8.73255 7.32546 10.1284 6.46285 11.7108C5.8205 12.8891 4.46842 13.6033 3.19205 13.1886V13.1886Z',
  },
]

export const CircularProgress = ({
  progressIndex,
}: {
  progressIndex: number
}) => {
  const getFillColor = (index: number): string =>
    index <= progressIndex ? '#2861FE' : '#BFBFBF'

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {SEGMENTS.map((segment, index) => (
        <motion.path
          key={index}
          d={segment.d}
          fill={getFillColor(index + 1)}
          initial={{ fill: '#BFBFBF' }}
          animate={{
            fill: index + 1 <= progressIndex ? '#2861FE' : '#BFBFBF',
          }}
          transition={{
            duration: 0.3,
            type: 'spring',
          }}
        />
      ))}
    </svg>
  )
}
